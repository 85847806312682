.app{
	color: #002d5b;
  font-size: 18px;
  padding-bottom: 60px;
}

.navbar-logo{
    height:30px;
    width:auto;
}

.galLaszlo{
  padding-right: 10px;
  padding-bottom: 10px;
}

.menu {
  background-color: #002d5b;
}

.menu-icon {
	color: white !important;
  background-color:  white !important;
}

.white-text {
	color: white !important;
}

.navbar-brand.title{
  font-size: 1.7rem;
  font-family: serif;
}

.navbar-light .navbar-toggle .icon-bar{
  color: white !important;
}

.navbar.right {
   align-items: right !important;
 }

 .tp-col {
   padding-top: 10px;
 }

 .tp-col-l {
   padding-top: 20px;
 }

 .tp-col-bl {
   padding-top: 40px;
 }

 .cv{
   text-align: justify;
 }
